import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { AiFillGithub, AiOutlineLinkedin, AiOutlineMail } from "react-icons/ai";
import { Link } from "react-scroll";
import logo from "../../assets/logo.webp";
import { SOCIAL_MEDIAS, TABS } from "../shared-constants";

//// Styling Component ----------------------------------------------------------------------
const styles = {
  iconPointer: {
    cursor: "pointer",
    fontSize: "30",
  },
};

const BackgroundBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  padding: "50px 0px",
  zIndex: "-3",
}));

const SkeletonFooterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "70%",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

const CustomFooterBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    marginBottom: "1rem",
    flexDirection: "column-reverse",
    alignItems: "center",
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: "1.2rem",
  },
}));

const CustomIconsBox = styled(Box)(({ theme }) => ({
  width: "140px",
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    padding: "18px 0 30px 0",
  },
}));

const MyImageLogo = styled(Box)(({ theme }) => ({
  width: "200px",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const CustomLinkTabs = styled(Link)(({ theme }) => ({
  cursor: "pointer",
  fontWeight: "700",
  textDecoration: "none",
  color: "inherit",
}));

const CustomTabsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "3rem",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    gap: "1.4rem",
  },
}));

//// --------------------------------------------------------------------------------------------

export const Footer = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <footer>
      <BackgroundBox>
        <SkeletonFooterBox>
          <CustomFooterBox>
            <MyImageLogo src={logo} component="img" alt="My Logo" />

            <CustomTabsBox>
              <CustomLinkTabs
                to={TABS["home"][1]}
                href={`#${TABS["home"][1]}`}
                smooth
              >
                {TABS["home"][0]}
              </CustomLinkTabs>
              <CustomLinkTabs
                to={TABS["skills"][1]}
                offset={-171}
                href={`#${TABS["skills"][1]}`}
                smooth
              >
                {TABS["skills"][0]}
              </CustomLinkTabs>
              <CustomLinkTabs
                to={TABS["projects"][1]}
                href={`#${TABS["projects"][1]}`}
                smooth
              >
                {TABS["projects"][0]}
              </CustomLinkTabs>
              <CustomLinkTabs
                to={TABS["contact"][1]}
                href={`#${TABS["contact"][1]}`}
                smooth
              >
                {TABS["contact"][0]}
              </CustomLinkTabs>
            </CustomTabsBox>
          </CustomFooterBox>
          <CustomFooterBox>
            <Typography>Copyright &copy;2024. All Rights Reserved</Typography>
            <CustomIconsBox>
              <AiOutlineLinkedin
                style={styles.iconPointer}
                onClick={() => openInNewTab(SOCIAL_MEDIAS["linkedIn"][1])}
              />
              <AiFillGithub
                style={styles.iconPointer}
                onClick={() => openInNewTab(SOCIAL_MEDIAS["gitHub"][1])}
              />
              <AiOutlineMail
                onClick={() => openInNewTab(SOCIAL_MEDIAS["email"][1])}
                style={styles.iconPointer}
              />
            </CustomIconsBox>
          </CustomFooterBox>
        </SkeletonFooterBox>
      </BackgroundBox>
    </footer>
  );
};
