import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import React from "react";
import {
  AiFillAndroid,
  AiFillApple,
  AiFillGithub,
  AiOutlineGlobal,
} from "react-icons/ai";
import projImg1 from "../../assets/project-img1.webp";
import projImg3 from "../../assets/job_board.webp";
import projImg4 from "../../assets/foodwise.webp";
import projImg5 from "../../assets/tinyapp.webp";
import projImg6 from "../../assets/gamelist.webp";
import projImg2 from "../../assets/project-img2.webp";
import { PROJECT_LINKS, TABS } from "../shared-constants";

//// Styling Component ----------------------------------------------------------------------

const BackgroundBox = styled(Box)(({ theme }) => ({
  padding: "100px 0px",
  marginTop: "100px",
  position: "relative",
  backgroundColor: "#121212",
  display: "flex",
  width: "100%",
  justifyContent: "center",
}));

const SkeletonBox = styled(Box)(({ theme }) => ({
  width: "70%",
}));

const ProjectTitleTypography = styled(Box)(({ theme }) => ({
  fontSize: "45px",
  fontWeight: 700,
  marginBottom: "20px",
}));

const ProjectDescriptionTypography = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  textAlign: "justify",
}));

const CustomProjectsSection = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  marginTop: "3rem",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const CustomIndividualProjectImage = styled(Box)(({ theme }) => ({
  maxHeight: "380px",
  maxWidth: "400px",
  objectFit: "fit",
  [theme.breakpoints.down("lg")]: {
    maxHeight: "280px",
    maxWidth: "300px",
  },
  [theme.breakpoints.down("md")]: {
    maxHeight: "280px",
    maxWidth: "200px",
  },
  [theme.breakpoints.down("sm")]: {
    maxHeight: "400px",
    maxWidth: "400px",
  },
}));

const CustomIndividualProjectDescription = styled(Box)(({ theme }) => ({
  padding: "0px 20px",
  margin: "0px 0px 40px 0",
  [theme.breakpoints.down("lg")]: {
    fontSize: "0.9rem",
  },
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
}));

const CustomInnerProjectButtonLink = styled(Button)(({ theme }) => ({
  border: "2px solid white",
  fontWeight: "700",
  color: "white",
  width: "40%",
  fontSize: "0.8rem",
  margin: "0px 0px 20px 0px",

  "&:hover": {
    backgroundColor: "black",
    color: "white",
    border: "2px solid black",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "0.6rem",
  },
  [theme.breakpoints.down("md")]: {
    width: "48%",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "0px 0px 15px 0px",
    width: "90%",
  },
}));

const CustomInnerProjectButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const CustomInnerProjectTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: "700",
  letterSpacing: "0.8px",
  lineHeight: "0.8em",
  marginBottom: "20px",
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

const CustomizeIconSocialMedia = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {
    display: "flex",
  },
}));

//// --------------------------------------------------------------------------------------------

const Projects = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <main>
      <BackgroundBox id={TABS["projects"][1]}>
        <SkeletonBox>
          <ProjectTitleTypography>Projects</ProjectTitleTypography>
          <ProjectDescriptionTypography>
            I love using trendy technologies to build projects from the ground
            up, and I take great pride in my work.
          </ProjectDescriptionTypography>
          <CustomProjectsSection>
            <div className="proj-imgbx" alt="">
              <CustomIndividualProjectImage
                src={projImg3}
                component="img"
                alt="Project 3"
              />
              <div className="proj-txtx">
                <CustomInnerProjectTitleTypography>
                  Jobnomics
                </CustomInnerProjectTitleTypography>
                <CustomIndividualProjectDescription>
                  AI-powered job tracker (React + Node.js) that automates data
                  collection, and organization to optimize job search efforts.
                </CustomIndividualProjectDescription>
                <CustomInnerProjectButtonLink
                  variant="outlined"
                  onClick={() => openInNewTab(PROJECT_LINKS["jobnomics"])}
                >
                  <CustomizeIconSocialMedia>
                    <AiOutlineGlobal size={20} />
                    &nbsp;
                  </CustomizeIconSocialMedia>
                  Website
                </CustomInnerProjectButtonLink>
                <CustomInnerProjectButtonContainer>
                  <CustomInnerProjectButtonLink
                    variant="outlined"
                    onClick={() => openInNewTab(PROJECT_LINKS["jobnomicsFE"])}
                  >
                    <CustomizeIconSocialMedia>
                      <AiFillGithub size={20} />
                      &nbsp;
                    </CustomizeIconSocialMedia>
                    Frontend
                  </CustomInnerProjectButtonLink>
                  <CustomInnerProjectButtonLink
                    variant="outlined"
                    onClick={() => openInNewTab(PROJECT_LINKS["jobnomicsBE"])}
                  >
                    <CustomizeIconSocialMedia>
                      <AiFillGithub size={20} />
                      &nbsp;
                    </CustomizeIconSocialMedia>
                    Backend
                  </CustomInnerProjectButtonLink>
                </CustomInnerProjectButtonContainer>
              </div>
            </div>
            <div className="proj-imgbx" alt="">
              <CustomIndividualProjectImage
                src={projImg6}
                component="img"
                alt="Project 3"
              />
              <div className="proj-txtx">
                <CustomInnerProjectTitleTypography>
                  GameList
                </CustomInnerProjectTitleTypography>
                <CustomIndividualProjectDescription>
                  Discover and track your favorite games effortlessly with
                  GameList
                </CustomIndividualProjectDescription>
                <CustomInnerProjectButtonLink
                  variant="outlined"
                  onClick={() => openInNewTab(PROJECT_LINKS["gamelist"])}
                >
                  <CustomizeIconSocialMedia>
                    <AiOutlineGlobal size={20} />
                    &nbsp;
                  </CustomizeIconSocialMedia>
                  Website
                </CustomInnerProjectButtonLink>
                <CustomInnerProjectButtonContainer>
                  <CustomInnerProjectButtonLink
                    variant="outlined"
                    onClick={() =>
                      openInNewTab(PROJECT_LINKS["gamelistFeGitHub"])
                    }
                  >
                    <CustomizeIconSocialMedia>
                      <AiFillGithub size={20} />
                      &nbsp;
                    </CustomizeIconSocialMedia>
                    Frontend
                  </CustomInnerProjectButtonLink>
                  <CustomInnerProjectButtonLink
                    variant="outlined"
                    onClick={() =>
                      openInNewTab(PROJECT_LINKS["gamelistBeGitHub"])
                    }
                  >
                    <CustomizeIconSocialMedia>
                      <AiFillGithub size={20} />
                      &nbsp;
                    </CustomizeIconSocialMedia>
                    Backend
                  </CustomInnerProjectButtonLink>
                </CustomInnerProjectButtonContainer>
              </div>
            </div>
          </CustomProjectsSection>
          <CustomProjectsSection>
            <div className="proj-imgbx" alt="">
              <CustomIndividualProjectImage
                src={projImg1}
                component="img"
                alt="Project 1"
              />
              <div className="proj-txtx">
                <CustomInnerProjectTitleTypography>
                  REME
                </CustomInnerProjectTitleTypography>
                <CustomIndividualProjectDescription>
                  REME is a reminder website that can be integrated with your
                  Google Calendar. The app is written using React.js +
                  TypeScript.
                </CustomIndividualProjectDescription>
                <CustomInnerProjectButtonLink
                  variant="outlined"
                  onClick={() => openInNewTab(PROJECT_LINKS["reme"])}
                >
                  <CustomizeIconSocialMedia>
                    <AiOutlineGlobal size={20} />
                    &nbsp;
                  </CustomizeIconSocialMedia>
                  Website
                </CustomInnerProjectButtonLink>
                <CustomInnerProjectButtonContainer>
                  <CustomInnerProjectButtonLink
                    variant="outlined"
                    onClick={() => openInNewTab(PROJECT_LINKS["frontend"])}
                  >
                    <CustomizeIconSocialMedia>
                      <AiFillGithub size={20} />
                      &nbsp;
                    </CustomizeIconSocialMedia>
                    Frontend
                  </CustomInnerProjectButtonLink>
                  <CustomInnerProjectButtonLink
                    variant="outlined"
                    onClick={() => openInNewTab(PROJECT_LINKS["backend"])}
                  >
                    <CustomizeIconSocialMedia>
                      <AiFillGithub size={20} />
                      &nbsp;
                    </CustomizeIconSocialMedia>
                    Backend
                  </CustomInnerProjectButtonLink>
                </CustomInnerProjectButtonContainer>
              </div>
            </div>
            <div className="proj-imgbx">
              <CustomIndividualProjectImage
                src={projImg4}
                component="img"
                alt="Project 4"
              />
              <div className="proj-txtx" alt="">
                <CustomInnerProjectTitleTypography>
                  FoodWise
                </CustomInnerProjectTitleTypography>
                <CustomIndividualProjectDescription>
                  Food Pick-up Ordering (Node.js) is a web application that
                  allows users to create pick up orders for their favorite
                  restaurants
                </CustomIndividualProjectDescription>
                {/* <CustomInnerProjectButtonLink
                  variant="outlined"
                  onClick={() => openInNewTab(PROJECT_LINKS["foodwise"])}
                >
                  <CustomizeIconSocialMedia>
                    <AiOutlineGlobal size={20} />
                    &nbsp;
                  </CustomizeIconSocialMedia>
                  Website
                </CustomInnerProjectButtonLink> */}
                <CustomInnerProjectButtonContainer>
                  <CustomInnerProjectButtonLink
                    variant="outlined"
                    onClick={() =>
                      openInNewTab(PROJECT_LINKS["foodwiseGitHub"])
                    }
                  >
                    <CustomizeIconSocialMedia>
                      <AiFillGithub size={20} />
                      &nbsp;
                    </CustomizeIconSocialMedia>
                    GitHub
                  </CustomInnerProjectButtonLink>
                </CustomInnerProjectButtonContainer>
              </div>
            </div>
          </CustomProjectsSection>
          <CustomProjectsSection>
            <div className="proj-imgbx">
              <CustomIndividualProjectImage
                src={projImg5}
                component="img"
                alt="Project 5"
              />
              <div className="proj-txtx" alt="">
                <CustomInnerProjectTitleTypography>
                  Tiny App
                </CustomInnerProjectTitleTypography>
                <CustomIndividualProjectDescription>
                  TinyApp (Node.js and Express.js) allows users to shorten long
                  URLs (à la bit.ly).
                </CustomIndividualProjectDescription>
                {/* <CustomInnerProjectButtonLink
                  variant="outlined"
                  onClick={() => openInNewTab(PROJECT_LINKS["tinyapp"])}
                >
                  <CustomizeIconSocialMedia>
                    <AiOutlineGlobal size={20} />
                    &nbsp;
                  </CustomizeIconSocialMedia>
                  Website
                </CustomInnerProjectButtonLink> */}
                <CustomInnerProjectButtonContainer>
                  <CustomInnerProjectButtonLink
                    variant="outlined"
                    onClick={() => openInNewTab(PROJECT_LINKS["tinyappGitHub"])}
                  >
                    <CustomizeIconSocialMedia>
                      <AiFillGithub size={20} />
                      &nbsp;
                    </CustomizeIconSocialMedia>
                    GitHub
                  </CustomInnerProjectButtonLink>
                </CustomInnerProjectButtonContainer>
              </div>
            </div>
            <div className="proj-imgbx">
              <CustomIndividualProjectImage
                src={projImg2}
                component="img"
                alt="Project 2"
              />
              <div className="proj-txtx" alt="">
                <CustomInnerProjectTitleTypography>
                  DishHub
                </CustomInnerProjectTitleTypography>
                <CustomIndividualProjectDescription>
                  DishHub, written in Flutter, is a social food review app
                  trusted by 2,000+ users in Calgary and we are actively
                  collaborating with GlobalFest.
                </CustomIndividualProjectDescription>
                <CustomInnerProjectButtonContainer>
                  <CustomInnerProjectButtonLink
                    variant="outlined"
                    onClick={() => openInNewTab(PROJECT_LINKS["iosDishHub"])}
                  >
                    <CustomizeIconSocialMedia>
                      <AiFillApple size={20} />
                      &nbsp;
                    </CustomizeIconSocialMedia>
                    IOS
                  </CustomInnerProjectButtonLink>
                  <CustomInnerProjectButtonLink
                    variant="outlined"
                    onClick={() =>
                      openInNewTab(PROJECT_LINKS["androidDishHub"])
                    }
                  >
                    <CustomizeIconSocialMedia>
                      <AiFillAndroid size={20} />
                      &nbsp;
                    </CustomizeIconSocialMedia>
                    Android
                  </CustomInnerProjectButtonLink>
                </CustomInnerProjectButtonContainer>
              </div>
            </div>
          </CustomProjectsSection>
        </SkeletonBox>
      </BackgroundBox>
    </main>
  );
};

export default Projects;
